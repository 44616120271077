export const rubros = [
		{
			
			"nombre": "Normatividad",
			"icono": "Normatividad.png",
			"formatos": [
				{
					"nombre": "Marco Normativo",
					"xlsx": "./Transparencia/xlsx/a69_f01_SC.xlsx",
					//   "urlXlsx" : "/Transparencia/xlsx/2023constancias.xlsx",
					"html": "./Transparencia/htm/a69_f01_SC.htm",
				//   "urlHtml" : "/public/Transparencia/xlsx/normatividad.xlsx"
				}
			],
			// "url" : "https://www.google.com"
		}, 
		{
			
			"nombre": "Estructura",
			"icono":"Estructura.png",
			"formatos": [
				{
					"nombre": "Estructura Orgánica",
					"xlsx": "./Transparencia/xlsx/a69_f02a_SC.xlsx",
					// "urlXlsx" : "/public/Transparencia/xlsx/pachuco.xlsx",
					"html": "./Transparencia/htm/a69_f02a_SC.htm"
				},
				{
					"nombre": "Organigrama",
					"xlsx": "./Transparencia/xlsx/a69_f02b_SC.xlsx",
					"html":  "./Transparencia/htm/a69_f02b_SC.htm"
				}
			]
		},
		{
			
			"nombre": "Facultades",
			"icono":"Facultades.png",
			"formatos": [
				{
					"nombre": "Facultades",
					"xlsx": "./Transparencia/xlsx/a69_f03_SC.xlsx",
					"html": "./Transparencia/htm/a69_f03_SC.htm",
				}
			]
		},
		{
			
			"nombre": "Programas Operativos Anuales",
			"icono":"ProgramasOperativosAnuales.png",
			"formatos": [
				{
					"nombre": "Metas y Objetivos",
					"xlsx": "./Transparencia/xlsx/a69_f04_SC.xlsx",
					"html": "./Transparencia/htm/a69_f04_SC.htm",
				}
			]
			
		},
		{
			
			"nombre": "Indicadores de Interés",
			"icono":"IndicadoresInteres.png",
			"formatos": [
				{
					"nombre": "Indicadores de Interés",
					"xlsx": "./Transparencia/xlsx/a69_f05_SC.xlsx",
					"html": "./Transparencia/htm/a69_f05_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Matriz de Indicadores",
			"icono":"MatrizIndicadores.png",
			"formatos": [
				{
					"nombre": "Matriz de Indicadores",
					"xlsx": "./Transparencia/xlsx/a69_f06_SC.xlsx",
					"html": "./Transparencia/htm/a69_f06_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Directorio",
			"icono":"Directorio.png",
			"formatos": [
				{
					"nombre": "Directorio",
				"xlsx": "./Transparencia/xlsx/a69_f07_SC.xlsx",
					"html": "./Transparencia/htm/a69_f07_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Sueldos",
			"icono":"Sueldos.png",
			"formatos": [
				{
					"nombre": "Remuneraciones",
					"xlsx": "./Transparencia/xlsx/pachuco.xlsx",
					"html": "./Transparencia/xlsx/pachuco.htm"
				},
				{
					"nombre": "Tabulador de Sueldos y Salarios",
					"xlsx": "./Transparencia/xlsx/pachuco.xlsx",
					"html": "./Transparencia/xlsx/pachuco.htm"
				},
			],
			"url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
		},
		{
			
			"nombre": "Viáticos",
			"icono":"Viaticos.png",
			"formatos": [
				{
					"nombre": "Viáticos",
					"xlsx": "./Transparencia/xlsx/a69_f09_SC.xlsx",
					"html": "./Transparencia/htm/a69_f09_SC.htm"
				}
			]	
		},
		{
			
			"nombre": "Plazas",
			"icono":"Plazas.png","formatos": [
				{
					"nombre": "Total de Personal de Base y Confianza",
					// "xlsx": "./Transparencia/xlsx/pachuco.xlsx",
					//  "urlHtml" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f10_aOM.htm"
				},
				{
					"nombre": "Total de las Plazas de Base y Confianza",
					// "xlsx": "./Transparencia/xlsx/pachuco.xlsx",
					// "html": "./Transparencia/xlsx/pachuco.htm"
	// "url" : "https://www.google.com"
					// https://s-contraloria.hidalgo.gob.mx/transparencia/48rubros/Declaraciones_Publicas.html
				}
			]	,
		   "url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas"
		},
		{
			
			"nombre": "Honorarios",
			"icono":"Honorarios.png",
			"formatos": [
				{
					"nombre": "Personal de Honorarios",
					"xlsx": "./Transparencia/xlsx/a69_f11_SC.xlsx",
					"html": "./Transparencia/htm/a69_f11_SC.htm"
				
				}
			]	
		},
		{
			"nombre": "Declaraciones Públicas",
			"icono":"DeclaracionesPublicas.png",
			"formatos": [
				{
					"nombre": "Declaraciones Patrimoniales",
					"xlsx": "./Transparencia/xlsx/a69_f12_I_SC.xlsx",
					"html": "./Transparencia/htm/a69_f12_I_SC.htm"
				},
				{
					"nombre": "Declaraciones Patrimoniales",
					"xlsx": "./Transparencia/xlsx/a69_f12_II_SC.xlsx",
					"html": "./Transparencia/htm/a69_f12_II_SC.htm"
  			}
			]	
		},
		{
			"nombre": "Datos de Contacto de la Unidad",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					"nombre": "Domicilio de la Unidad de Transparencia",
					"xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					"html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			]
		},
		{
			
			"nombre": "Convocatorias",
			"icono":"Convocatorias.png",
			"formatos": [
				{
					"nombre": "Convocatorias a Concursos para Cargos Públicos",
					"xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					"html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			  "url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Convocatorias"
		},
		{
			
			"nombre": "Programas Sociales",
			"icono":"ProgramasSociales.png",
			"formatos": [
				{
					"nombre": "Programas de Subsidio, Estimulo y Apoyo",
					"xlsx": "./Transparencia/xlsx/a69_f15a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f15a_SC.htm"
					
					
				},
				{
					"nombre": "Padrón de beneficiarios",
					"xlsx": "./Transparencia/xlsx/a69_f15b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f15b_SC.htm"
					
				}
			]
		},
		{
			
			"nombre": "Relaciones Laborales",
			"icono":"RelacionesLaborales.png",
			"formatos": [
				{
					"nombre": "Condiciones Generales de Trabajo",
					"xlsx": "f16_a",
					"html": "f16_a"
				},
				{
					"nombre": "Recursos Públicos",
					"xlsx": "f16_b",
					"html": "f16_b"
				}
			],
			  "url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales"
		},
		{
			
			"nombre": "Información Curricular",
			"icono":"InformacionCurricular.png",
			"formatos": [
				{
					"nombre": "Información Curricular",
					"xlsx": "./Transparencia/xlsx/a69_f17_SC.xlsx",
					"html": "./Transparencia/htm/a69_f17_SC.htm"
					
				}
			]
		},
		{
			
			"nombre": "Sanciones Administrativas",
			"icono":"SancionesAdministrativas.png",
			"formatos": [
				{
					"nombre": "Servidores Públicos Sancionados",
					"xlsx": "./Transparencia/xlsx/a69_f18_SC.xlsx",
					"html": "./Transparencia/htm/a69_f18_SC.htm"
				}
			]	
		},
		{
			
			"nombre": "Servicios",
			"icono":"Servicios.png",
			"formatos": [
				{
					"nombre": "Servicios",
					"xlsx": "./Transparencia/xlsx/a69_f19_SC.xlsx",
					"html": "./Transparencia/htm/a69_f19_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Trámites",
			"icono":"Tramites.png",
			"formatos": [
				{
					"nombre": "Trámites",
					"xlsx": "./Transparencia/xlsx/a69_f20_SC.xlsx",
					"html": "./Transparencia/htm/a69_f20_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Presupuesto e Informes Trimestrales",
			"icono":"PresupuestoInformesTrimestrales.png",
			"formatos": [
				{
					"nombre": "Presupuesto Anual",
					"xlsx": "f21_a",
					"html": "f21_a"
				},
				{
					"nombre": "Gasto Trimestral",
					"xlsx": "f21_b",
					"html": "f21_b"
				},
				{
					"nombre": "Cuenta Pública",
					"xlsx": "f21_c",
					"html": "f21_c"
				}
			],
			"url": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Presupuesto_e_Informes_Semestrales.html"
		},
		{
			
			"nombre": "Deuda Pública",
			"icono":"DeudaPublica.png",
			"formatos": [
				{
					"nombre": "Deuda Pública",
					"xlsx": "f22",
					"html": "f22"
				}
			],
			"url": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Deuda_Publica.htm"
		},
		{
			
			"nombre": "Comunicación Social y Publicidad",
			"icono":"ComunicacionSocialPublicidad.png",
			"formatos": [
				{
					"nombre": "Programa Anual de Comunicación Social",
					"xlsx": "./Transparencia/xlsx/a69_f23a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f23a_SC.htm"
				},
				{
					"nombre": "Erogación de Recursos de Comunicación Social",
					"xlsx": "./Transparencia/xlsx/a69_f23b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f23b_SC.htm"
				},
				{
					"nombre": "Tiempos Oficiales",
					// "xlsx": "f23_c",
					// "html": "f23_c",
					// "xlsx_liga": "http://radioytelevision.hidalgo.gob.mx/transparencia/xlsx/a69_f23_c.xlsx",
					 "htm_liga": "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/2024/Transparencia/htm/a69_f23_c_RTVH.htm"
				}

			]
		},
		{
			
			"nombre": "Resultados de Auditorías",
			"icono":"ResultadosAuditorias.png",
			"formatos": [
				{
					"nombre": "Auditorías",
					"xlsx": "./Transparencia/xlsx/a69_f24_SC.xlsx",
					"html": "./Transparencia/htm/a69_f24_SC.htm"
				}
			]
		},
		{
			
			"nombre": "Dictámines de Estados Financiero ",
			"icono":"DictamenesFinancieros.png",
			"formatos": [
				{
					"nombre": "Estados Financieros",
					"xlsx": "f25",
					"html": "f25"
				}
			],
		"url" : "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Dictamenes_de_Estados_Financieros.html"
			
		},
		{
			
			"nombre": "Asignación de Recursos Públicos a Personas",
			"icono":"AsignacionRecursosPublicosPersonas.png",
			"formatos": [
				{
					"nombre": "Personas a Quienes se asignó Recursos Públcios",
					"xlsx": "./Transparencia/xlsx/a69_f26_SC.xlsx",
					"html": "./Transparencia/htm/a69_f26_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Concesiones",
			"icono":"Concesiones.png",
			"formatos": [
				{
					"nombre": "Concesiones, Contratos, Convenios, Permisos, Licencias y Autorizaciones",
					"xlsx": "./Transparencia/xlsx/a69_f27_SC.xlsx",
					"html": "./Transparencia/htm/a69_f27_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Licitaciones Públicas",
			"icono":"LicitacionesPublicas.png",
			"formatos": [
				{
					"nombre": "Procedimientos de Adjudicación Directa, Licitación Pública e Invitación Restringida",
					"xlsx": "./Transparencia/xlsx/a69_f28a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f28a_SC.htm"
					
				}
				// ,
				// {
				// 	"nombre": "Procedimientos de Adjudicación Directa",
				// 	"xlsx": "f28_b",
				// 	"html": "f28_b"
				// }
			]
			
		},
		{
			
			"nombre": "Informes",
			"icono":"Informes.png",
			"formatos": [
				{
					"nombre": "Informes",
					"xlsx": "./Transparencia/xlsx/a69_f29_SC.xlsx",
					"html": "./Transparencia/htm/a69_f29_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Estadísticas Generadas",
			"icono":"EstadisticasGenerales.png",
			"formatos": [
				{
					"nombre": "Estadísticas",
					"xlsx": "./Transparencia/xlsx/a69_f30_SC.xlsx",
					"html": "./Transparencia/htm/a69_f30_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Informe de Avances y Documentos Financiero",
			"icono":"InformeAvancesDocumentosFinancieros.png",
			"formatos": [
				{
					"nombre": "Informe de Estados Financieros",
					"xlsx": "f31_a",
					"html": "f31_a"
				},
				{
					"nombre": "Informe Contable, Presupuestal y Programático",
					"xlsx": "f31_b",
					"html": "f31_b"
				}
			],
			"url":"https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Informe_de_Avances_y_Documentos_Financieros.html"
			
		},
		{
			
			"nombre": "Padrón de Proveedores y Contratistas",
			"icono":"PadronProveedoresContratistas.png",
			"formatos": [
				{
					"nombre": "Padrón de Proveedores y Contratistas",
						"xlsx": "./Transparencia/xlsx/a69_f32_SC.xlsx",
					"html": "./Transparencia/htm/a69_f32_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Convenios con Sectores Social y Privado",
			"icono":"ConveniosSectoresSocialPrivado.png",
			"formatos": [
				{
					"nombre": "Convenios de Coordinación y Concertación",
					"xlsx": "./Transparencia/xlsx/a69_f33_SC.xlsx",
					"html": "./Transparencia/htm/a69_f33_SC.htm"
					
				}
			]
			
		},
		{
			
			"nombre": "Inventario de Bienes",
			"icono":"InventarioBienes.png",
			"formatos": [
				{
					"nombre": "Inventario de Bienes Muebles",
					"xlsx": "f34_a",
					"html": "f34_a"
				},
				{
					"nombre": "Alta de Bienes Muebles",
					"xlsx": "f34_b",
					"html": "f34_b"
				},
				{
					"nombre": "Bajas de Bienes Muebles",
					"xlsx": "f34_c",
					"html": "f34_c"
				},
				{
					"nombre": "Inventario de Bienes Inmuebles",
					"xlsx": "f34_d",
					"html": "f34_d"
				},
				{
					"nombre": "Alta de Bienes Inmuebles",
					"xlsx": "f34_e",
					"html": "f34_e"
				},
				{
					"nombre": "Bajas de Bienes Inmuebles",
					"xlsx": "f34_f",
					"html": "f34_f"
				},
				{
					"nombre": "Donaciones de Bienes Muebles e Inmuebles",
					"xlsx": "f34_g",
					"html": "f34_g"
				}

			],
			"url": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/34.%20Inventario%20de%20Bienes"
			
		},
		{
			
			"nombre": "Recomendaciones y Atención en Materia de Derechos Humanos",
			"icono":"RecomendacionesAtenciónMateriaDerechosHumanos.png",
			"formatos": [
				{
					"nombre": "Recomendaciones de Derechos Humanos Emitidas por la CNDH",
					"xlsx": "./Transparencia/xlsx/a69_f35a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f35a_SC.htm"
				},
				{
					"nombre": "Recomendaciones de Derechos Humanos Casos Especiales",
					"xlsx": "./Transparencia/xlsx/a69_f35b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f35b_SC.htm"
				},
				{
					"nombre": "Las Recomendaciones de Derechos Humanos Org. Int. CNDH",
					"xlsx": "./Transparencia/xlsx/a69_f35c_SC.xlsx",
					"html": "./Transparencia/htm/a69_f35c_SC.htm"
				},
			]
			
		},
		{
			
			"nombre": "Resoluciones y Laudos de Juicios",
			"icono":"ResolucionesLaudosJuicios.png",
			"formatos": [
				{
					"nombre": "Resoluciones y Laudos ",
					"xlsx": "./Transparencia/xlsx/a69_f36_SC.xlsx",
					"html": "./Transparencia/htm/a69_f36_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Mecanismo de Participación Ciudadana",
			"icono":"MecanismosParticipacionCiudadana.png",
			"formatos": [
				{
					"nombre": "Mecanismos de Participación Ciudadana",
					"xlsx": "./Transparencia/xlsx/a69_f37a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f37a_SC.htm"
				},
				{
					"nombre": "Resultados de los Mecanismos de Participación Ciudadana",
					"xlsx": "./Transparencia/xlsx/a69_f37b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f37b_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Programas Ofrecidos",
			"icono":"ProgramasOfrecidos.png",
			"formatos": [
				{
					"nombre": "Programas que Ofrecen",
					"xlsx": "f38_a",
					"html": "f38_a"
				},
				{
					"nombre": "Tramites a los Programas que Ofrecen",
					"xlsx": "f38_b",
					"html": "f38_b"
				}
			],
			"url": "https://s-salud.hidalgo.gob.mx/Transparencia/RubrosSsh/DetalleRubroSsh/38.%20Programas%20Ofrecidos"
			
		},
		{
			
			"nombre": "Actas y Resoluciones del Comité de Transparencia",
			"icono":"ActasResolucionesComiteTransparencia.png",
			"formatos": [
				{
					"nombre": "Informe de Sesiones",
					"xlsx": "./Transparencia/xlsx/a69_f39a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f39a_SC.htm"
				},
				{
					"nombre": "Informe de Resoluciones",
					"xlsx": "./Transparencia/xlsx/a69_f39b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f39b_SC.htm"
				},
				{
					"nombre": "Actas Integrantes del Comité",
					"xlsx": "./Transparencia/xlsx/a69_f39c_SC.xlsx",
					"html": "./Transparencia/htm/a69_f39c_SC.htm"
				},
				{
					"nombre": "Calendario de Sesiones del comité",
					"xlsx": "./Transparencia/xlsx/a69_f39d_SC.xlsx",
					"html": "./Transparencia/htm/a69_f39d_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Evaluacion y Encuesta a Programas Financiados con Recursos Públicos",
			"icono":"EvaluacionencuestasProgramas.png",
			"formatos": [
				{
					"nombre": "Evaluaciones",
					"xlsx": "f40_a",
					"html": "f40_a"
				},
				{
					"nombre": "Encuestas",
					"xlsx": "f40_b",
					"html": "f40_b"
				}
			],
			"url": "https://s-finanzas.hidalgo.gob.mx/transparencia/48rubros/Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos.html"	
			
		},
		{
			
			"nombre": "Estudios Pagados con Recursos Públcos",
			"icono":"EstudiosPagadosRecursosPublicos.png",
			"formatos": [
				{
					"nombre": "Estudios Pagados con Recursos Públicos ",
					"xlsx": "./Transparencia/xlsx/a69_f41_SC.xlsx",
					"html": "./Transparencia/htm/a69_f41_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Listados Jubilados y Pensionados",
			"icono":"ListadoJubiladosPencionados.png",
			"formatos": [
				{
					"nombre": "Jubilados y Pensionados Leyenda",
					"xlsx": "f42_a",
					"html": "f42_a"
				},
				{
					"nombre": "Jubilados y Pensionados Listado",
					"xlsx": "f42_b",
					"html": "f42_b"
				}
			],	
			"url" : "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados" 
		},
		{
			
			"nombre": "Ingresos Recibidos por Cualquier Concepto",
			"icono":"IngresosRecibidosCualquierConcepto.png",
			"formatos": [
				{
					"nombre": "Ingresos Recibidos",
					"xlsx": "./Transparencia/xlsx/a69_f43a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f43a_SC.htm"
					
				},
				{
					"nombre": "Responsables de Recibir los Ingresos",
					"xlsx": "./Transparencia/xlsx/a69_f43b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f43b_SC.htm"
				}
			]	
			
		},
		{
			
			"nombre": "Donaciones Hechas a Terceros",
			"icono":"DonacionesHechasTerceros.png",
			"formatos": [
				{
					"nombre": "Donaciones en dinero y en especie realizadas",
					"xlsx": "./Transparencia/xlsx/a69_f44a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f44a_SC.htm"
				},
				// {
				// 	"nombre": "Donaciones Hechas a Terceros en Especie",
				// 	"xlsx": "f44_b",
				// 	"html": "f44_b"
				// }
			]	
			
		},
		{
			
			"nombre": "Catálogo Documental y Guia de Archivos",
			"icono":"Viaticos.png",
			"formatos": [
				{
					"nombre": "Catálogo de Disposición y Guía de Archivos",
					"xlsx": "./Transparencia/xlsx/a69_f45a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f45a_SC.htm"
				},
				{
					"nombre": "Catálogo de Disposición y Guía de Archivos",
					"xlsx": "./Transparencia/xlsx/a69_f45b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f45b_SC.htm"
				},
				{
					"nombre": "Catálogo de Disposición y Guía de Archivos",
					"xlsx": "./Transparencia/xlsx/a69_f45c_SC.xlsx",
					"html": "./Transparencia/htm/a69_f45c_SC.htm"
				}
			]
			
		},
		{
			
			"nombre": "Actas de Sesiones, Opiniones y Recomendaciones de Consejos Consultivos",
			"icono":"CatalogoDocumentalGuiaArchivos.png",
			"formatos": [
				{
					"nombre": "Actas del Consejo Consultivo Ciudadano",
					"xlsx": "f46_a",
					"html": "f46_a"
				},
				{
					"nombre": "Opiniones del Consejo Consultivo Ciudadano",
					"xlsx": "f46_b",
					"html": "f46_b"
				}
			],	
			"url":"https://s-turismo.hidalgo.gob.mx/transparencia/48rubros/Actas_de_Sesiones_Opiniones_y_Recomendaciones_de_Consejos_Consultivos.html"
		},
		{
			
			"nombre": "Solicitud de Intervecion de  Comunicaciones",
			"icono":"SolicitudIntervencionComunicaciones.png",
			"formatos": [
				{
					"nombre": "Intervenciones de comunicaciones",
					"xlsx": "f47_a",
					"html": "f47_a"
				},
				{
					"nombre": "Localización Geográfica",
					"xlsx": "f47_b",
					"html": "f47_b"
				},
				{
					"nombre": "Mensaje de Solicitudes a empresas para la intervención de comunicaciones privadas",
					"xlsx": "f47_c",
					"html": "f47_c"
				}
			],
			"url":"https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones"
		},
		{
			
			"nombre": "Información de Utilidad o Relevante",
			"icono":"InformacionUtilidadRelevante.png",
			"formatos": [
				{
					"nombre": "Interés Público",
					"xlsx": "./Transparencia/xlsx/a69_f48a_SC.xlsx",
					"html": "./Transparencia/htm/a69_f48a_SC.htm"
				},
				{
					"nombre": "Preguntas Frecuentes",
					"xlsx": "./Transparencia/xlsx/a69_f48_b_SC.xlsx",
					"html": "./Transparencia/htm/a69_f48_b_SC.htm"
				},
				{
					"nombre": "Transparencia Proactiva",
					"xlsx": "./Transparencia/xlsx/a69_f48c_SC.xlsx",
					"html": "./Transparencia/htm/a69_f48c_SC.htm"
				}
			]

			
		},
]