import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
// import { ligas } from '../../json/ligas';
import { provees } from '../../json/provees'; 
import './prov_contra.css';

const Ligas = () => {  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='title'>Proveedores y Contratistas</h1>
            <hr className='hr-gob'/>
          </div>
          {
                 provees.map((item, index)=>(
                  <Link key={ index }  target="_blank" to={ item.url ? item.url : "DetalleRubros/" + item.nombre } className='col-md-4 col-sm-6 col-12 ligas_enlace'>
                    <div className='ligas_container'>
                      <div className='ligas_icono'>
                        <img src={"http://cdn.hidalgo.gob.mx/plantilla_secretarial/Rubros/PNG/" + item.icono }/>
                      </div>
                      <h3>{ item.nombre }</h3>
                    </div>
                  </Link>
                ))
            }
            {/* <Link className='rubros_historicos' to="RubrosHistoricos">Información Ejercicios Anteriores Art. 69 (48 Fracciones)</Link> */}
        </div>
    </div>
  )
}

export default Ligas