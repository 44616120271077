export const Armonizacion = [
    // {
    //     "titulo" : "Información Contable",
    //     "subtitulo" : [
    //         {
    //             "informacionTitulo" : "Estado de Actividades"
    //         }
    //     ]
    // },
    // {
    //     "titulo" : "Disciplina Financiera"
    // },
    // {
    //     "titulo" : "Información Presupuestaria"
    // },
    // {
    //     "titulo" : "ejemplo",
    //     "subtitulo" : [
    //         {
    //             "informacionTitulo" : "Ejemplo1.1"
    //         },
    //         {
    //             "informacionTitulo" : "Ejemplo1.2"
    //         }
    //     ]
    // },

    {
        "titulo" : "Ejercicios",
        "subtitulo" : [
            {
                "informacionTitulo" : "2024"
            },
            {
                "informacionTitulo" : "2023"
            },
            {
                "informacionTitulo" : "2022"
            },
            {
                "informacionTitulo" : "2021"
            },
            {
                "informacionTitulo" : "2020"
            },
            {
                "informacionTitulo" : "2019"
            },
        ]
    }
];