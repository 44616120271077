export const agenda = [
  {
      'id': 1,
      'title': 'Asesorías a personas físicas y morales sobre recursos administrativos.',
      'place': 'Pachuca',
      'start': '2024-08-15',
      'color': 'var(--wine)',
      'description': '<blockquote class="twitter-tweet"><p lang="es" dir="ltr"> El día de hoy proporcionamos asesorías a personas físicas y morales sobre recursos administrativos y métodos alternativos de resolución de controversias, derivados de procedimientos de contratación. <a href="https://twitter.com/Contraloria_Hgo/status/1824211557741375515?ref_src=twsrc%5Etfw">Febrero 26, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
  },
  {
      'id': 2,
      'title': 'Evento 2 variable',
      'place': 'Guadalajara',
      'start': '2023-03-10',
      'color': 'var(--wine)',
      'description': '<blockquote class="twitter-tweet"><p lang="es" dir="ltr"><a href="https://twitter.com/hashtag/ConstruyendolaPazySeguridad?src=hash&amp;ref_src=twsrc%5Etfw">#ConstruyendolaPazySeguridad</a>🕊️ de Hidalgo<a href="https://twitter.com/hashtag/Tepeapulco?src=hash&amp;ref_src=twsrc%5Etfw">#Tepeapulco</a> fue sede de la Reunión de Coordinación para la Construcción de Paz y Seguridad Regional, presidida por el secretario de gobierno Guillermo Olivares donde se genero un diálogo entre municipios, autoridades estatales y federales <a href="https://t.co/pnE5w2QjD0">pic.twitter.com/pnE5w2QjD0</a></p>&mdash; Secretaría de Gobierno de Hidalgo (@SEGOBHidalgo) <a href="https://twitter.com/SEGOBHidalgo/status/1629603439041708033?ref_src=twsrc%5Etfw">February 25, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
  }
  ,
  {
      'id': 3,
      'title': 'Evento 3 Hex',
      'place': 'Guadalajara',
      'start': '2023-03-03',
      'color': 'var(--wine)',
      'description': '<blockquote class="twitter-tweet"><p lang="es" dir="ltr"><a href="https://twitter.com/hashtag/ConstruyendolaPazySeguridad?src=hash&amp;ref_src=twsrc%5Etfw">#ConstruyendolaPazySeguridad</a>🕊️ de Hidalgo<a href="https://twitter.com/hashtag/Tepeapulco?src=hash&amp;ref_src=twsrc%5Etfw">#Tepeapulco</a> fue sede de la Reunión de Coordinación para la Construcción de Paz y Seguridad Regional, presidida por el secretario de gobierno Guillermo Olivares donde se genero un diálogo entre municipios, autoridades estatales y federales <a href="https://t.co/pnE5w2QjD0">pic.twitter.com/pnE5w2QjD0</a></p>&mdash; Secretaría de Gobierno de Hidalgo (@SEGOBHidalgo) <a href="https://twitter.com/SEGOBHidalgo/status/1629603439041708033?ref_src=twsrc%5Etfw">February 25, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
  },
  {
      'id': 4,
      'title': 'Hallowen',
      'place': 'Canada',
      'start': '2023-10-31',
      'color': 'var(--wine)',
      'description': '<blockquote class="twitter-tweet"><p lang="es" dir="ltr"><a href="https://twitter.com/hashtag/ConstruyendolaPazySeguridad?src=hash&amp;ref_src=twsrc%5Etfw">#ConstruyendolaPazySeguridad</a>🕊️ de Hidalgo<a href="https://twitter.com/hashtag/Tepeapulco?src=hash&amp;ref_src=twsrc%5Etfw">#Tepeapulco</a> fue sede de la Reunión de Coordinación para la Construcción de Paz y Seguridad Regional, presidida por el secretario de gobierno Guillermo Olivares donde se genero un diálogo entre municipios, autoridades estatales y federales <a href="https://t.co/pnE5w2QjD0">pic.twitter.com/pnE5w2QjD0</a></p>&mdash; Secretaría de Gobierno de Hidalgo (@SEGOBHidalgo) <a href="https://twitter.com/SEGOBHidalgo/status/1629603439041708033?ref_src=twsrc%5Etfw">February 25, 2023</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
  }
];
