import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './programa.css';
import { titular } from '../../json/titular';
import { Link } from 'react-router-dom';
import { carouselInicio } from '../../json/carousel';

const Programa = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Carousel bannerData={carouselInicio} search={true} name="banner-principal" />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='secretaria_logo' >
              <div className='secretaria_logo_img' style={{ backgroundImage: `url("https://cdn.hidalgo.gob.mx/gobierno/images/iconosSec/Contraloria.svg")` }}>

              </div>
            </div>
          </div>
          <div className='secretaria_name'>
            <h1>SECRETARÍA DE CONTRALORÍA</h1>
          </div>
          <div className='secretaria_funciones'>
            {/* <h3 className='title'>SÚPER AGENTES </h3>
            <hr className='hr-gob'/>
            <p>Los Súper Agentes de la Legalidad están en nuestro Estado y juntos lucharemos contra la Corrupción.</p> */}
              <div className='btn_organigrama'>
                {/* <Link to="/Transparencia/Rubros/DetalleRubro/Estructura">Organigrama</Link> */}
                {/* <Link to="/Transparencia/Rubros/DetalleRubro/Normatividad">Normatividad</Link> */}
              </div>
              <div className='secretaria-redes'>
                <a href=''><i class="fa-brands fa-facebook-f"></i></a>
                <a href=''><i class="fa-brands fa-instagram"></i></a>
                <a href=''><i class="fa-brands fa-twitter"></i></a>
              </div>
          </div>
        </div>
        <UltimasNoticias/>
        <div className='row'>
          <div className='col-12'>
            <div className='notes_title'>
              <h3 className='title'>TITULAR DE LA SECRETARÍA</h3>
              <hr className='hr-gob'/>
            </div>
            <div className='section_notes'>
              {
                titular.map((item, index) =>(
                    <>
                      <div key={ index } className='sec_photo'>
                        <div className='img_secretario' style={{ backgroundImage: `url("${ item.imagen }")` }}>
                        </div>
                      </div>
                      <div className='sec_container'>
                        <div className='sec_name'>
                          <h3>{ item.nombre }</h3>
                        </div>
                        <div className='sec_function'>
                          <h4>Función del Secretario</h4>
                          <p>{ item.funcion }</p>
                          <div className='btn_agenda'>
                            <Link to="Agenda">Conoce la agenda del Secretario</Link>
                          </div>
                        </div>
                      </div>
                    </>
                ))
              }
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='section_map'>
              <div className='map_name'>
                <h3 className='title'>UBICACIÓN</h3>
                <hr className='hr-gob'/>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d662.3643106695378!2d-98.77699098597304!3d20.096534989166187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1a1ef446e7f3d%3A0xecf4b7e8af163950!2sSecretar%C3%ADa+de+Contralor%C3%ADa!5e0!3m2!1ses-419!2smx!4v1547919757134"
                      width="100%" 
                      height="300" 
                      frameborder="0" 
                      style={{border:0, marginBottom:50}} 
                      allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>   
      </div>
    </>
  )
}

export default Programa