export const contratistas = [
		{
			"nombre": "Inscripción Persona Moral. Las personas físicas y morales interesadas.",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Las personas físicas y morales interesadas.",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			 "url" : "https://ruts.hidalgo.gob.mx/ver/392"
		},
		{
			"nombre": "Inscripción Persona Física. Las personas físicas y morales interesadas.",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Domicilio de la Unidad de Transparencia",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			"url" : "https://ruts.hidalgo.gob.mx/ver/406"
		},
		{
			"nombre": "Revalidación Persona Moral. Las personas físicas y morales interesadas.",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Domicilio de la Unidad de Transparencia",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			
			"url" : "https://ruts.hidalgo.gob.mx/ver/409"
		},
		{
			"nombre": "Revalidación Persona Física. Las personas físicas y morales interesadas.",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Domicilio de la Unidad de Transparencia",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			"url" : "https://ruts.hidalgo.gob.mx/ver/412"
		},
		{
			"nombre": "Actualización Persona Moral. Las personas físicas y morales interesadas.",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Domicilio de la Unidad de Transparencia",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			"url" : "https://ruts.hidalgo.gob.mx/ver/1602"
		},
		{
			"nombre": "Actualización Persona Física. Las personas físicas y morales interesadas.",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Domicilio de la Unidad de Transparencia",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			"url" : "https://ruts.hidalgo.gob.mx/ver/1599"
		}



]