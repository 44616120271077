export const rubrosHistoricos = [
    {
        "anio" : "2024",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
            //  "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
                "url" : "/Transparencia/zip/primer_trimestre_2024.zip"
             }
            // {
            //     "trimestre" : "Segundo Trimestre",
            //     "url" : "www.google.com"
            // },
            // {
            //     "trimestre" : "Tercer Trimestre",
            //     "url" : "www.google.com"
            // },
            // {
            //     "trimestre" : "Cuarto Trimestre",
            //     "url" : "www.google.com"
            // }
        ],
      
    },
    {
        "anio" : "2023",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
               //  "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
                    "url" : "/Transparencia/zip/primer_trimestre_2023.zip"
                    
                 },
                {
                    "trimestre" : "Segundo Trimestre",
                     "url" : "/Transparencia/zip/segundo_trimestre_2023.zip"
                },
                {
                    "trimestre" : "Tercer Trimestre",
                     "url" : "/Transparencia/zip/tercer_trimestre_2023.zip"
                },
                {
                    "trimestre" : "Cuarto Trimestre",
                     "url" : "/Transparencia/zip/cuarto_trimestre_2023.zip"
                }
            
        ]
    },
    {
        "anio" : "2022",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
               //  "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
                    "url" : "/Transparencia/zip/primer_trimestre_2022.rar"
                    
                 },
                {
                    "trimestre" : "Segundo Trimestre",
                     "url" : "/Transparencia/zip/segundo_trimestre_2022.zip"
                },
                {
                    "trimestre" : "Tercer Trimestre",
                     "url" : "/Transparencia/zip/tercer_trimestre_2022.zip"
                },
                {
                    "trimestre" : "Cuarto Trimestre",
                     "url" : "/Transparencia/zip/cuarto_trimestre_2022.zip"
                }
            
        ]
    },
    {
        "anio" : "2021",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
               //  "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
                    "url" : "/Transparencia/zip/primer_trimestre_2021.rar"
                    
                 },
                {
                    "trimestre" : "Segundo Trimestre",
                     "url" : "/Transparencia/zip/segundo_trimestre_2021.rar"
                },
                {
                    "trimestre" : "Tercer Trimestre",
                     "url" : "/Transparencia/zip/tercer_trimestre_2021.zip"
                },
                {
                    "trimestre" : "Cuarto Trimestre",
                     "url" : "/Transparencia/zip/cuarto_trimestre_2021.rar"
                }
            
        ]
    },
    {
        "anio" : "2020",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
               //  "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
                    "url" : "/Transparencia/zip/Primer_trimestre_2020.rar"
                    
                 },
                {
                    "trimestre" : "Segundo Trimestre",
                     "url" : "/Transparencia/zip/Segundo_trimestre_2020.rar"
                },
                {
                    "trimestre" : "Tercer Trimestre",
                     "url" : "/Transparencia/zip/Tercer_trimestre_2020.rar"
                },
                {
                    "trimestre" : "Cuarto Trimestre",
                     "url" : "/Transparencia/zip/Cuarto_trimestre_2020.rar"
                }
            
        ]
    },
    {
        "anio" : "2019",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
               //  "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
                    "url" : "/Transparencia/zip/2019Primer_trimestre_SC.rar"
                    
                 },
                {
                    "trimestre" : "Segundo Trimestre",
                     "url" : "/Transparencia/zip/2019Segundo_trimestre_SC.rar"
                },
                {
                    "trimestre" : "Tercer Trimestre",
                     "url" : "/Transparencia/zip/2019Tercer_trimestre_SC.rar"
                },
                {
                    "trimestre" : "Cuarto Trimestre",
                     "url" : "/Transparencia/zip/2019Cuarto_trimestre_SC.rar"
                }
            
        ]
    },
    {
        "anio" : "2018",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
               //  "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos"
                    "url" : "/Transparencia/zip/Primer_trimestre_SC.rar"
                    
                 },
                {
                    "trimestre" : "Segundo Trimestre",
                     "url" : "/Transparencia/zip/Segundo_trimestre_SC.rar"
                },
                {
                    "trimestre" : "Tercer Trimestre",
                     "url" : "/Transparencia/zip/Tercer_trimestre_SC.rar"
                },
                {
                    "trimestre" : "Cuarto Trimestre",
                     "url" : "/Transparencia/zip/Cuarto_trimestre_SC.rar"
                }
            
        ]
    },
    {
        "anio" : "Información Histórica 21 Rubros",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Descargar",
                 "url" : "/Transparencia/zip/21_rubroes.rar"
              
            }
        ]
    }
]