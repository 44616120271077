export const transparencia_item = [
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "/Transparencia/Rubros"
    },
    {
        "transparencia_item_nombre" : "Rubros Historicos",
        "transparencia_item_url" : "/Transparencia/Rubros/RubrosHistoricos"
    },
    {
        "transparencia_item_nombre" : "Recurso Federal 1 y 5 al Millar",
        "transparencia_item_url" : "/Armonizacion"
    },
    {
        "transparencia_item_nombre" : "Recurso Estatal 5 al Millar",
        "transparencia_item_url" : "/Recursos"
      
    }
    // {
    //     "transparencia_item_nombre" : "Licitaciones",
    //     "transparencia_item_url" : "/Licitaciones"
    // },
    // {
    //     "transparencia_item_nombre" : "Licitaciones",
    //     "transparencia_item_url" : "/Licitaciones"
    // }

];