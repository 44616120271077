export const carouselInicio = [
   {
      "imagen" : "videos/Portada.gif",

       
   }
   // {

   //    "imagen" : "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png"

   // }
   // ,
   // {

   //    "imagen" : "https://cdn.hidalgo.gob.mx/transicion/fondotransicion.png"

   // }
   
   
 
]