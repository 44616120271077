export const Recursos = [
    // {
    //     "titulo" : "Información Contable",
    //     "subtitulo" : [
    //         {
    //             "informacionTitulo" : "Estado de Actividades"
    //         }
    //     ]
    // },
    // {
    //     "titulo" : "Disciplina Financiera"
    // },
    // {
    //     "titulo" : "Información Presupuestaria"
    // },
    // {
    //     "titulo" : "ejemplo",
    //     "subtitulo" : [
    //         {
    //             "informacionTitulo" : "Ejemplo1.1"
    //         },
    //         {
    //             "informacionTitulo" : "Ejemplo1.2"
    //         }
    //     ]
    // },

    {
        "titulo" : "Recurso Estatal",
        "subtitulo" : [
            {
                "informacionTitulo" : "Ejercicio Fiscal 2024"
            },
            {
                "informacionTitulo" : "Ejercicio Fiscal 2023"
            },
            {
                "informacionTitulo" : "Ejercicio Fiscal 2022"
            },
            {
                "informacionTitulo" : "Ejercicio Fiscal 2021"
            },
            {
                "informacionTitulo" : "Ejercicio Fiscal 2020"
            },
            {
                "informacionTitulo" : "Ejercicio Fiscal 2019"
            },
            {
                "informacionTitulo" : "Ejercicio Fiscal 2018"
            }
        ]
    }
];