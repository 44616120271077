import { estadoActividades, disciplinaFinanciera, informacionPresupuestaria, ejemplo11,Ejercicios, Ejercicios2022, Ejercicios2023, Recurso2024, Recurso2023, Recurso2022, Recurso2021, Recurso2020,Recurso2019, Recurso2018 } from "../json/anioArmonizacion";
import { disFinanciera } from "../json/anioDisciplina";


export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {
    case 'Estado de Actividades':
      datosSeleccionados = estadoActividades;
      break;
    case 'Disciplina Financiera':
      datosSeleccionados = disciplinaFinanciera;
      break;
    case 'Información Presupuestaria':
      datosSeleccionados = informacionPresupuestaria;
      break;
    case 'Ejemplo1.1':
      datosSeleccionados = ejemplo11;
      break;
  //  case 'Ejemplo1.2':
  //     datosSeleccionados = ejemplo12;
  //     break;
    case '2024':
      datosSeleccionados = Ejercicios;
      break;
    case '2023':
      datosSeleccionados = Ejercicios2023;
      break;
    case '2022':
      datosSeleccionados = Ejercicios2022;
      break;
    case 'Ejercicio Fiscal 2024':
    datosSeleccionados = Recurso2024;
    break;
    case 'Ejercicio Fiscal 2023':
    datosSeleccionados = Recurso2023;
    break;
    case 'Ejercicio Fiscal 2022':
    datosSeleccionados = Recurso2022;
    break;
    case 'Ejercicio Fiscal 2021':
    datosSeleccionados = Recurso2021;
    break;
    case 'Ejercicio Fiscal 2020':
    datosSeleccionados = Recurso2020;
    break;
    case 'Ejercicio Fiscal 2019':
    datosSeleccionados = Recurso2019;
    break;
    case 'Ejercicio Fiscal 2018':
    datosSeleccionados = Recurso2018;
    break;
    default:
      datosSeleccionados = [];
  }

  return datosSeleccionados;
};



export const obtenerDatosSeleccionadosDisciplina = (selectedItemIndex) => {
  let datosSeleccionadosDisciplina = [];
  switch (selectedItemIndex) {
    case 'este es el titulo disciplina financiera':
      datosSeleccionadosDisciplina = disFinanciera;
      break;
    default:
      datosSeleccionadosDisciplina = [];
  }

  return datosSeleccionadosDisciplina;
};