import React, { useEffect } from 'react';
 import { Link } from 'react-router-dom';
import './tramite.css';
import { tramites } from '../../json/tramites';

const Tramites = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return (
      <div className='container container-mh'>
        <div className='row'>
          <div className='col-12'>
            <h3 className='title'>Tramites y Servicios</h3>
            <hr className='hr-gob' />
          </div>
        </div>
        <div className='row'>
        {
              tramites.map((tramite, index1) => (
                 <div key={index1} className='col-12 container-armon'>
                  <p>
                    <button
                      className='btn btn-primary contenido-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#tramite-${index1}`}
                      aria-expanded='false'
                      aria-controls={`tramite-${index1}`}
                    >
                      {tramite.anio}
                    </button>
                  </p>
                  {
                
                    tramite.tramitesLista.map((lista, index2) => (
                      <div
                        key={`${index1}-${index2}`}
                        className='collapse multi-collapse'
                        id={`tramite-${index1}`}
                      >
                        <p>
                          <button
                            className='btn btn-primary contenido-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target={`#lista-${index1}-${index2}`}
                            aria-expanded='false'
                            aria-controls={`lista-${index1}-${index2}`}
                          >
                            {lista.nombre}
                          </button>
                        </p>
                        {
                          
                          lista.contenidoTramite.map((contenido, index3) => (
                              <div
                              key={`${index1}-${index2}-${index3}-${index3}`}
                              className='collapse multi-collapse armo-collapse'
                              id={`lista-${index1}-${index2}`}
                            >
                              {
                                 contenido.url ?
                                  <a
                                    href={contenido.url}
                                    target='_blank'
                                    className='btn btn-primary btn-armo-collapse'
                                  >
                                    {contenido.nombre}
                                  </a> : null
                                
                              }
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              ))
            }
        </div>
      </div>
    );
  };

export default Tramites