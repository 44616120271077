import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Transparencia from './components/Transparencia/Transparencia';
import Tramite from "./components/Tramite/Tramite";
import Ligas from "./components/Ligas/Ligas";
import Rubros from './components/Rubros/Rubros';
import Prov_contra from './components/Prov_contra/Prov_contra';
import Proveedores from "./components/Proveedores/Proveedores";
import Contratistas from "./components/Contratistas/Contratistas";
import DetalleRubro from './components/DetalleRubro/DetalleRubro';
import RubrosHistoricos from "./components/RubrosHistoricos/RubrosHistoricos";
import Agenda from "./components/Agenda/Agenda";
import ArmonizacionContable from "./components/ArmonizacionContable/ArmonizacionContable";
import RecursoContable from "./components/RecursoContable/RecursoContable";
import Licitaciones from "./components/Licitaciones/Licitaciones";
import Error404 from "./components/Error404/Error404";

////nuevos
import Programa from "./components/Programa/Programa";
// import Agente from "./components/Agente/Agente";
import { Armonizacion } from "./json/armonizacionContable";
import { Recursos } from "./json/recursoContable";
import { Disciplina } from "./json/disciplinaFinanciera";




function App() {
  return (
    <BrowserRouter>
      <Header/>
        <Routes>
          <Route exact path="/" element={ <Inicio /> } />
          <Route exact path="/Transparencia" element={ <Transparencia/> } />
          <Route exact path="/Transparencia/Rubros" element={ <Rubros/> } />
          <Route exact path="/Transparencia/Rubros/DetalleRubro/:nombre" element={ <DetalleRubro/> } />
          <Route exact path="/Transparencia/Rubros/RubrosHistoricos" element={ <RubrosHistoricos/> } />
          <Route exact path="/Agenda" element={ <Agenda/> } />
          <Route exact path="/Tramite" element={ <Tramite/> } />
          <Route exacr path="/Tramite/Contratistas" element={ <Contratistas/> } />
          <Route exact path="/Tramite/Proveedores" element={ <Proveedores/> } />
          <Route exact path="/Ligas" element= { <Ligas/> } />
          <Route exact path="/Prov_contra" element= { <Prov_contra/> } />
          <Route exact path="/Armonizacion" element={ <ArmonizacionContable data={Armonizacion} title="Recurso Federal 1 y 5 al Millar" dataType="/Armonizacion" /> } />
          <Route exact path="/Recursos" element={ <RecursoContable data={Recursos} title="Recurso Estatal 5 al Millar" dataType="/Recursos" /> } />
          <Route exact path="/Disciplina" element={ <ArmonizacionContable data={Disciplina} title="Disciplina Financiera" dataType="/Disciplina" /> } />
          <Route exact path="/Licitaciones" element={ <Licitaciones /> } />
      

          /////nuevo
          <Route exact path="/Programa" element={ <Programa/> } />
          {/* <Route exact path="/Agente" element={ <Agente/> } /> */}

          <Route exact path="*" element={<Error404 /> } />
        </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
