import React, { useEffect } from 'react';
//  import { proveedores } from '../../json/proveedores';
import { contratistas } from '../../json/contratistas';
import './contratistas.css';
import { Link } from 'react-router-dom';

const Contratistas = () => {  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='title'>Padrón de Contratistas</h1>
            <hr className='hr-gob'/>
          </div>
            {
              contratistas.map((item, index)=>(
                <Link key={ index } to={ item.url ? item.url : "DetalleRubro/" + item.nombre } className='col-md-4 col-sm-6 col-12 rubro_enlace'>
                  <div className='rubro_container'>
                    <div className='rubro_icono'>
                      <img src={"http://cdn.hidalgo.gob.mx/plantilla_secretarial/Rubros/PNG/" + item.icono }/>
                    </div>
                    <h3>{ item.nombre }</h3>
                  </div>
                </Link>
              ))
            }
            {/* <Link className='rubros_historicos' to="RubrosHistoricos">Información Ejercicios Anteriores Art. 69 (48 Fracciones)</Link> */}
        </div>
    </div>
  )
}

export default Contratistas