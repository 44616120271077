export const licitaciones = [
    {
        "anio" : "Direccion General de Responsabilidades Administrativas",
        "licitacionesLista" : [
            {
                "nombre" : "Servicios",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "Atención de Quejas y Denuncias",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/443"
                    },
                    {
                        "nombre" : "Constancias de No Inhabilitación",
                        "url" : "https://contraloriahidalgo.gob.mx/inhabilitacion/constancias/"
                    },
                    {
                        "nombre" : "Inconformidades en Materia de Adquisiciones",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/425"
                    },
                    {
                        "nombre" : "Inconformidades en Materia de Obra Pública",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/424"
                    },
                    {
                        "nombre" : "Sistema de Declaración Patrimonial DeclaraNet",
                        "url" : "http://declaranet.hidalgo.gob.mx:5000/"
                    }
                ]
            }
        ]
    },
    {
        "anio" : "Dirección General de Normatividad",
        "licitacionesLista" : [
            {
                "nombre" : "Servicios",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "Padrón de Proveedores",
                        "url" : "https://www.google.com"
                    },
                    {
                        "nombre" : "Padrón de Contratistas",
                        "url" : "https://www.google.com"
                    },
                    {
                        "nombre" : "Revisión de Bases",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/480"
                    },
                    {
                        "nombre" : "Sistema de Registro de Proveedores y Contratistas",
                        "url" : "https://www.contraloriahidalgo.gob.mx/rext/s"
                    }
                ]
            }
        ]
    },
    {
        "anio" : "Dirección General de Vinculación",
        "licitacionesLista" : [
            {
                "nombre" : "Servicios",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "Asesoría y Capacitación en Materia de Contraloría Social",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/426"
                    },
                    {
                        "nombre" : "Fortalecimiento de valores y cultura a la legalidad para niños y niñas",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/6584"
                    },
                    {
                        "nombre" : "Recepción y Seguimiento de Quejas, Denuncias y Solicitudes de Contraloría Social",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/437"
                    }
                ]
            }
        ]
    },
    {
        "anio" : "Dirección General de Auditoría Gubernamental",
        "licitacionesLista" : [
            {
                "nombre" : "Servicios",
                "contenidoLicitacion" : [
                    {
                        "nombre" : "Quejas de Obra Pública",
                        "url" : "https://ruts.hidalgo.gob.mx/ver/6582"
                    }
                ]
            }
        ]
    }
]