export const ultimasNoticias = [
    {
        "imagen" : "noticias/photo_508156556646546565.jpeg",
        "url" : "https://segundoinforme.hidalgo.gob.mx/",
        "titulo" : "Segundo Informe",
        "fecha" : "06 de septiembre del 2024"
    },
    {
        "imagen" : "noticias/photo_5080301009967885554_y.jpeg",
        "url" : "https://segundoinforme.hidalgo.gob.mx/",
        "titulo" : "Segundo Informe",
        "fecha" : "06 de septiembre del 2024"
    },
    {
        "imagen" : "noticias/photo_5080301009967885589_y.jpeg",
        "url" : "https://segundoinforme.hidalgo.gob.mx/",
        "titulo" : "Segundo Informe",
        "fecha" : "06 de septiembre del 2024"
    },
    {
        "imagen" : "noticias/codigo_QR.jpeg",
         "url" : "https://segundoinforme.hidalgo.gob.mx/",
        "titulo" : "Escanea y Cuéntanos",
        "fecha" : "06 de septiembre del 2024"
    }
]