
export const estadoActividades = [
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];

export const disciplinaFinanciera = [
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];

export const informacionPresupuestaria = [
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }
];

export const ejemplo11 = [
    {
        "anio" : "2030",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    },
    {
        "anio" : "2030",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ]
    }

];

export const Ejercicios = [
    {
        "anio" : " Ejercicio fiscal 2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Programación de Presupuesto Anual",
                "trimestreNombre1" : "Presupuesto 5 al Millar",
                
                "trimestreContenido" : [
                 
                ]
            },
            {
                "trimestreNombre" : "Informes Mensuales",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://localhost:3000/descargables/INFORME_MENSUAL_5ALMILLAR_ENERO_2024.pdf"
                        
                    }
                ]
            },
            {
                "trimestreNombre" : "Enero",
                "trimestreContenido" : [
                    {
                         "pdf" : " descargables/INFORME_MENSUAL_5ALMILLAR_ENERO_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_FEBRERO_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_MARZO_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                          "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_ABRIL_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_MAYO_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informes Acumulados",
                "trimestreContenido" : [
                 
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                           "pdf" : "descargables/INFORME_ACUMULADO_FEBRERO_5ALMILAR_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                          "pdf" : "descargables/INFORME_ACUMULADO_MARZO_5ALMILAR_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_ABRIL_5ALMILAR_2024"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_MAYO_5ALMILAR_2024"
                    }
                ]
            },
            {
                "trimestreNombre" : "1 al Millar",
                "trimestreContenido" : [
                  
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_1ER_TRIMESTRE_1ALMILLAR_2024.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Conciliaciones con la Secretaría de Finanzas Públicas",
                "trimestreContenido" : [
                    
                ]
            },
            {
                "trimestreNombre" : "Enero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MENSUAL_ENERO_FEDERAL_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MENSUAL_FEDERAL_FEBRERO_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MARZO_FEDERAL_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_ABRIL_FEDERAL_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MAYO_FEDERAL_2024.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Conciliaciones Trimestrales con la Secretaría de Finanzas Públicas",
                "trimestreContenido" : [
                    ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_1ER_TRIMESTRE_FEDERAL_2024.pdf"
                    }
                ]
            }
        ]
    }
];
export const Ejercicios2023 = [
    {
        "anio" : "Ejercicio fiscal 2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Programación de Presupuesto Anual",
                "trimestreNombre1" : "Presupuesto 5 al Millar",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/PRESUPUESTO_5ALMILLAR_2023.pdf"

                    }
                ]
            },
            {
                "trimestreNombre" : "Informes Mensuales",
                "trimestreContenido" : [
                 
                ]
            },
            {
                "trimestreNombre" : "Enero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_ENERO_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_FEBRERO_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_MARZO_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_ABRIL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_MAYO_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Junio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_JUNIO_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Julio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_JULIO_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Agosto",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_AGOSTO_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Septiembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_SEPTIEMBRE_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Octubre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_OCTUBRE_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Noviembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_NOVIEMBRE_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Diciembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_DICIEMBRE_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "INFORME CIERRE ANUAL 2023",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_CIERRE_ANUAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informes Acumulados",
                "trimestreContenido" : [
                 
                ]
            },
            // {
            //     "trimestreNombre" : "Enero",
            //     "trimestreContenido" : [
            //         {
            //             "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_ENERO_2023.pdf"
            //         }
            //     ]
            // },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_FEBRERO_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_MARZO_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_ABRIL_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_MAYO_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Junio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_JUNIO_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Julio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_JULIO_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Agosto",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_AGOSTO_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Septiembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_SEPTIEMBRE_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Octubre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_OCTUBRE_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Noviembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_NOVIEMBRE_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Diciembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_DICIEMBRE_5ALMILAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "1 al Millar",
                "trimestreContenido" : [
               
                 
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_1ER_TRIMESTRE_1ALMILLAR_2023.pdf"
                    }
                 
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_2DO_TRIMESTRE_1ALMILLAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_3ER_TRIMESTRE_1ALMILLAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_4TO_TRIMESTRE_1ALMILLAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "INFORME ANUAL 1ALMILLAR 2023",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ANUAL_1ALMILLAR_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Conciliaciones con la Secretaría de Finanzas Públicas",
                "trimestreContenido" : [
                    
                ]
            },
            {
                "trimestreNombre" : "Enero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_ENERO_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_FEBRERO_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MARZO_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_ABRIL_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MAYO_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Junio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_JUNIO_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Juiio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_JULIO_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Agosto",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_AGOSTO_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Septiembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_SEPTIEMBRE_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Octubre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_OCTUBRE_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Noviembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_NOVIEMBRE_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Diciembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_DICIEMBRE_FEDERAL_2023.pdf"
                    }
                ]
            },

            {
                "trimestreNombre" : "Conciliaciones Trimestrales con la Secretaría de Finanzas Públicas",
                "trimestreContenido" : [
                    ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_1ER_TRIMESTRE_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_2DO_TRIMESTRE_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_3ER_TRIMESTRE_FEDERAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_4TO_TRIMESTRE_FEDERAL_2023.pdf"
                    }
                ]
            }
        ]
    }

];
export const Ejercicios2022 = [
    {
        "anio" : "Ejercicio fiscal 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Programación de Presupuesto Anual",
                "trimestreNombre1" : "Presupuesto 5 al Millar",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/PRESUPUESTO_5ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informes Mensuales",
                "trimestreContenido" : [
                  
                ]
            },
            {
                "trimestreNombre" : "Enero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_ENERO_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_5ALMILLAR_FEBRERO_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_MARZO_5ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_ABRIL_5ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_MAYO_5ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Junio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_JUNIO_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Julio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_JULIO_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Agosto",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_AGOSTO_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Septiembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_SEPTIEMBRE_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Octubre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_OCTUBRE_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Noviembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_NOVIEMBRE_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Diciembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_MENSUAL_DICIEMBRE_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Cierre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_CIERRE_ANUAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informes Acumulados",
                "trimestreContenido" : [
                 
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ACUMULADO_FEBRERO_5ALMILAR.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "1 al Millar",
                "trimestreContenido" : [
                  
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_1ERTRIMESTRE_1ALMILLAR_2022.pdf"
                    }
                ]
            },
            { 
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_2DO_TRIMESTRE_1ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_3ERTRIMESTRE_1ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_4TO_TRIMESTRE_1ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Cierre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ANUAL_1ALMILLAR_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Conciliaciones con la Secretaría de Finanzas Públicas",
                "trimestreContenido" : [
                    
                ]
            },
            {
                "trimestreNombre" : "Enero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_ENERO_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Febrero",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_FEBRERO_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Marzo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MARZO_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Abril",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_ABRIL_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Mayo",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MAYO_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Junio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_JUNIO_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Julio",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_JULIO_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Agosto",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_AGOSTO_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Septiembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_SEPTIEMBRE_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Octubre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_OCTUBRE_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Noviembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_NOVIEMBRE_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Diciembre",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_MENSUAL_DICIEMBRE_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Conciliaciones Trimestrales con la Secretaría de Finanzas Públicas",
                "trimestreContenido" : [
                    ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_1ER_TRIMESTRE_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_2DO_TRIMESTRE_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_3ER_TRIMESTRE_FEDERAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/CONCILIACION_4TO_TRIMESTRE_2022.pdf"
                    }
                ]
            }
        ]
    }

    

];


export const Recurso2024 = [
    {
        "anio" : "Ejercicio fiscal 2024",
        "trimestre" : [
            {
                "trimestreNombre" : "Ingresos",
                 "trimestreNombre1" : "1er. Informe Trimestral",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/1er_TRIMESTRE_INGRESOS_5M_ESTATAL_2024.pdf"
                    }
                ]
            },
             {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2do_TRIMESTRE_INGRESOS_5M_ESTATAL_2024.pdf"
                    }
                ]
            },
           
            {
                "trimestreNombre" : "Egresos",
                "trimestreContenido" : [
                    {
                      
                    }
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                 {
                       "pdf" : "descargables/1er_TRIMESTRE_EGRESOS_5M_ESTATAL_2024.pdf" 
                 }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2do_TRIMESTRE_EGRESOS_5M_ESTATAL_2024.pdf"
                    }
                ]
            }
        ]
    }

    

];
export const Recurso2023 = [
    {
        "anio" : "Ejercicio fiscal 2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Ingresos",
                 "trimestreNombre1" : "1er. Informe Trimestral",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/1ER_TRIMESTRE_INGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
      
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_INGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_INGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_INGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ANUAL_EGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
                       
            {
                "trimestreNombre" : "Egresos",
                "trimestreContenido" : [
                    {
                      
                    }
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                 {
                       "pdf" : "descargables/1er_TRIMESTRE_EGRESOS_5M_ESTATAL_2023.pdf" 
                 }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2do_TRIMESTRE_EGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3er_TRIMESTRE_EGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4to_TRIMESTRE_EGRESOS_5M_ESTATAL_2023.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ANUAL_EGRESOS_5M_ESTATAL_2023 (1).pdf"
                    }
                ]
            }
        ]
    }
];
export const Recurso2022 = [
    {
        "anio" : "Ejercicio fiscal 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Ingresos",
                 "trimestreNombre1" : "1er. Informe Trimestral",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/1ER_TRIMESTRE_INGRESOS_ESTATAL_2022.pdf"
                    }
                ]
            },
      
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_INGRESOS_ESTATAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_INGRESOS_ESTATAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_INGRESOS_ESTATAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ANUAL_INGRESOS_5M_ESTATAL_2022.pdf"
                    }
                ]
            },
                       
            {
                "trimestreNombre" : "Egresos",
                "trimestreContenido" : [
                    {
                      
                    }
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                 {
                       "pdf" : "descargables/1ER_TRIMESTRE_EGRESOS_5M_ESTATAL_2022.pdf" 
                 }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_EGRESOS_5M_ESTATAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_EGRESOS_5M_ESTATAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables4TO_TRIMESTRE_EGRESOS_5M_ESTATAL_2022.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_ANUAL_EGRESOS_5M_ESTATAL_2022.pdf"
                    }
                ]
            }
        ]
    }
];
export const Recurso2021 = [
    {
        "anio" : "Ejercicio fiscal 2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Ingresos",
                 "trimestreNombre1" : "1er. Informe Trimestral",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/1ER_TRIMESTRE_INGRESOS_2021.pdf"
                    }
                ]
            },
      
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_INGRESOS_ESTATAL_2021.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_INGRESOS_ESTATAL_2021.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_INGRESOS_ESTATAL_2021.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_INGRESOS_ANUAL_ESTATAL_2021.pdf"
                    }
                ]
            },
                       
            {
                "trimestreNombre" : "Egresos",
                "trimestreContenido" : [
                    {
                      
                    }
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                 {
                       "pdf" : "descargables/1ER_TRIMESTRE_EGRESOS_2021.pdf" 
                 }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_EGRESOS_5M_ESTATAL_2021.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_EGRESOS_5M_ESTATAL_2021.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_EGRESOS_5M_ESTATAL_2021.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/INFORME_EGRESOS_ANUAL_5M_ESTATAL_2021.pdf"
                    }
                ]
            }
        ]
    }
];
export const Recurso2020 = [
    {
        "anio" : "Ejercicio fiscal 2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Ingresos",
                 "trimestreNombre1" : "1er. Informe Trimestral",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/1ER_TRIMESTRE_INGRESOS_2020.pdf"
                    }
                ]
            },
      
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_INGRESOS_2020.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_INGRESOS_2020.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_INGRESOS_2020.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/ANUAL_INGRESOS_2020.pdf"
                    }
                ]
            },
                       
            {
                "trimestreNombre" : "Egresos",
                "trimestreContenido" : [
                    {
                      
                    }
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                 {
                       "pdf" : "descargables/1ER_TRIMESTRE_EGRESOS_2020.pdf" 
                 }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_EGRESOS_2020.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_EGRESOS_2020.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO TRIMESTRE EGRESOS 2020.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/ANUAL_EGRESOS_2020.pdf"
                    }
                ]
            }
        ]
    }
];
export const Recurso2019 = [
    {
        "anio" : "Ejercicio fiscal 2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Ingresos",
                 "trimestreNombre1" : "1er. Informe Trimestral",
                
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/1ER_TRIMESTRE_INGRESOS_2019.pdf"
                    }
                ]
            },
      
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_INGRESOS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_INGRESOS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_INGRESOS_2019"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/ANUAL_INGRESOS_2019.pdf"
                    }
                ]
            },
                       
            {
                "trimestreNombre" : "Egresos",
                "trimestreContenido" : [
                    {
                      
                    }
                ]
            },
            {
                "trimestreNombre" : "1er. Informe Trimestral",
                "trimestreContenido" : [
                 {
                       "pdf" : "descargables/1ER_TRIMESTRE_EGRESOS_2019.pdf" 
                 }
                ]
            },
            {
                "trimestreNombre" : "2do. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/2DO_TRIMESTRE_EGRESOS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_EGRESOS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_EGRESOS_2019.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/ANUAL_EGRESO_ 2019.pdf"
                    }
                ]
            }
        ]
    }
];
export const Recurso2018 = [
    {
        "anio" : "Ejercicio fiscal 2018",
        "trimestre" : [
        
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_INGRESOS_2018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                       
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/ANUAL_INGRESOS_2018.pdf"
                    }
                ]
            },
                       
            {
                "trimestreNombre" : "Egresos",
                "trimestreContenido" : [
                    {
                      
                    }
                ]
            },
        
            {
                "trimestreNombre" : "3er. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/3ER_TRIMESTRE_EGRESOS_2018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "4to. Informe Trimestral",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/4TO_TRIMESTRE_EGRESOS_2018.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Informe Anual",
                "trimestreContenido" : [
                    {
                        "pdf" : "descargables/ANUAL_EGRESOS_2018.pdf"
                    }
                ]
            }
        ]
    }
];
// export const ejemplo12 = [
//     {
//         "anio" : "2024",
//         "trimestre" : [
//             {
//                 "trimestreNombre" : "Primer Trimestre",
//                 "trimestreContenido" : [
//                     {
//                         "excel" : "https://www.google.com",
//                         "pdf" : "https://www.google.com"
//                     }
//                 ]
//             }
//         ]
//     }
// ]