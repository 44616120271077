export const provees = [
	{
		"nombre": "Padrón de Proveedores y Contratistas de la Administración Pública del Estado de Hidalgo",
		"icono":"DatosContactoUnidad.png",
		"formatos": [
			{
				// "nombre": "Las personas físicas y morales interesadas.",
				//  "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
				//  "html": "./Transparencia/htm/a69_f13_SC.htm"
			}
		],
		  "url" : "https://contraloriahidalgo.gob.mx/sidec/padron/"
	},
	{
		"nombre": "Catálogo de Especialidades de Contratistas",
		"icono":"DatosContactoUnidad.png",
		"formatos": [
			{
				// "nombre": "Las personas físicas y morales interesadas.",
				// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
				// "html": "./Transparencia/htm/a69_f13_SC.htm"
			}
		],
			 "url" : "/interes/CATALOGO DE ESPECIALIDADES CONTRATISTAS_MAR 24.pdf"
	},
	{
		"nombre": "Sancionados",
		"icono":"DatosContactoUnidad.png",
		"formatos": [
			{
				// "nombre": "Las personas físicas y morales interesadas.",
				// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
				// "html": "./Transparencia/htm/a69_f13_SC.htm"
			}
		],
		//  "url" : "/interes/Prov_contra_sancionados.htm"
		 "url" : "/interes/Sancionados.htm"
		},
		{
			"nombre": "Catálogo de Especialidades de Proveedores",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Las personas físicas y morales interesadas.",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			 "url" : "/interes/CATALOGO%20DE%20ESPECIALIDADES%20PROVEEDORES%20LOGOS%20VEDA%2003-05-24.pdf"
			},
		   {
			"nombre": "Comunicado del Catálogo de Empresas Íntegras",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Las personas físicas y morales interesadas.",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			 "url" : "/interes/Catalogo_Convocatoria.pdf"
			}
			
			// {
			// 	"nombre": "Política Estatal Anticorrupción de Hidalgo",
			// 	"icono":"DatosContactoUnidad.png",
			// 	"formatos": [
			// 		{
			// 			// "nombre": "Las personas físicas y morales interesadas.",
			// 			// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
			// 			// "html": "./Transparencia/htm/a69_f13_SC.htm"
			// 		}
			// 	],
			// 	 "url" : "/interes/PoliticaEstatalAnticorrupcionDeHidalgo.pdf"
			// 	},

];