export const links = [
    
    {

        "nombre" : "Agenda",
        "link" : "/Agenda",

    },

    {
        "nombre" : "Transparencia",
        "link" : "/Transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "48 Rubros",
                "subMenuUrl" : "/Transparencia/Rubros"
            },
            {
                "subMenuNombre" : "Rubros Anteriores",
                "subMenuUrl" : "/Transparencia/Rubros/RubrosHistoricos"
            },
            {
                "subMenuNombre" : "Recurso Federal 1 y 5 al Millar",
                "subMenuUrl" : "/Armonizacion"
            },
            {
                "subMenuNombre" : "Recurso Estatal 5 al Millar",
                "subMenuUrl" : "/Recursos"
            }
            // ,
            // {
            //     "subMenuNombre" : "Licitaciones",
            //     "subMenuUrl" : "/Licitaciones"
            // },
            // {
            //     "subMenuNombre" : "Disciplina Financiera",
            //     "subMenuUrl" : "/Disciplina"
            // }
        ]
    },
    {

        "nombre" : "Trámites y Servicios",
        "link" : "/Tramite",
        "submenu" : [
            {
                "subMenuNombre" : "Padrón de Proveedores",
                 "subMenuUrl" : "/Tramite/Proveedores"
            },
            {
                "subMenuNombre" : "Padrón de Contratistas",
                 "subMenuUrl" : "/Tramite/Contratistas"
            },
            {
                "subMenuNombre" : "Constancias de No Inhabilitación",
                "subMenuUrl" : "https://contraloriahidalgo.gob.mx/inhabilitacion/constancias/"
            },
            {
                "subMenuNombre" : "Sistema de Declaración Patrimonial DeclaraNet",
                "subMenuUrl" : "http://declaranet.hidalgo.gob.mx:5000/"
            },
            {
                "subMenuNombre" : "Atención de Quejas y Denuncias",
                 "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/443/"
            },
            {
                "subMenuNombre" : "Quejas de Obra Pública",
                 "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/6582"
            }
        ]

    },
    {

        "nombre" : "Ligas de Interés",
        "link" : "/Ligas",
        "submenu" : [
            {
                "subMenuNombre" : "Proveedores y Contratistas",
                "subMenuUrl" : "/Prov_contra"
            },
            {
                "subMenuNombre" : "Sistema Padrón de Proveedores y Contratistas",
                 "subMenuUrl" : "https://contraloriahidalgo.gob.mx/sidec/padron/"
            }
          ]
    },

    // {

    //     "nombre" : "  Secretaría Función Pública",
    //     "link" : "/  Secretaría Función Pública",

    // },

    // {

    //     "nombre" : "Comisión Permanente",
    //     "link" : "/Comisión Permanente",

    // },

    // {

    //     "nombre" : "Súper Agentes",
    //     "link" : "/Programa",

    // }

]