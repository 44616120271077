export const ligas = [
	{
		"nombre" : "Servidores Públicos Sancionados.",
     	 "url" : "/interes/SancionadosdeHidalgo.htm",
		 "icono":"DatosContactoUnidad.png",
		
	},
	// {
	// 	"nombre": "Recurso Federal 1 y 5 al Millar",
	// 	"icono":"DatosContactoUnidad.png",
	// 	"formatos": [
	// 		{
	// 			// "nombre": "Las personas físicas y morales interesadas.",
	// 			// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
	// 			// "html": "./Transparencia/htm/a69_f13_SC.htm"
	// 		}
	// 	],
	// 	 "url" : "/Armonizacion"
	// },
	{
		"nombre": "Padrón de Proveedores y Contratistas Sancionados",
		"icono":"DatosContactoUnidad.png",
		"formatos": [
			{
				// "nombre": "Las personas físicas y morales interesadas.",
				// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
				// "html": "./Transparencia/htm/a69_f13_SC.htm"
				
			}
		],
		 "url" : "/interes/Prov_contra_sancionados.htm"
		//  "url" : "/Prov_contra"
		},
		{
			"nombre": "Convocatoria personas consejeras",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Las personas físicas y morales interesadas.",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			 "url" : "/interes/con_per_consejeras.pdf"
			},
		{
			"nombre": "Consejeros de la Secretaría para el PROTOCOLO CERO.",
			"icono":"DatosContactoUnidad.png",
			"formatos": [
				{
					// "nombre": "Las personas físicas y morales interesadas.",
					// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
					// "html": "./Transparencia/htm/a69_f13_SC.htm"
				}
			],
			 "url" : "/interes/CANDIDATOS_PROTOCOLO_CERO.pdf"
			},
			{
				"nombre": "Política Estatal Anticorrupción de Hidalgo",
				"icono":"DatosContactoUnidad.png",
				"formatos": [
					{
						// "nombre": "Las personas físicas y morales interesadas.",
						// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
						// "html": "./Transparencia/htm/a69_f13_SC.htm"
					}
				],
				 "url" : "/interes/PoliticaEstatalAnticorrupcionDeHidalgo.pdf"
				},
			// {
			// 	"nombre": "Recurso Estatal 5 al Millar",
			// 	"icono":"DatosContactoUnidad.png",
			// 	"formatos": [
			// 		{
			// 			// "nombre": "Las personas físicas y morales interesadas.",
			// 			// "xlsx": "./Transparencia/xlsx/a69_f13_SC.xlsx",
			// 			 "html": "/Recursos"
			// 		}
			// 	],
			// 	//  "url" : "/Recursos"
			// 	},

];